import * as bossClient from '@boss/boss-client';

import { transform } from './transformer';
import { IEvent } from './types';

export const getEvents = async (locale: string): Promise<IEvent[]> => {
  const events = await bossClient.event.getEvents(locale);

  return events?.map(event => transform({ event })) ?? [];
};

/**
 * Get all B2B trainings
 * @returns {Promise<IEvent[]>} - List of events
 */
export const getUserEvents = async (locale: string): Promise<IEvent[]> => {
  const events = await bossClient.userEventClient.getEvents(locale);

  return events?.map(event => transform({ event })) ?? [];
};

/**
 * Participate event by event id
 * @param {string} eventId - event ID
 * @param data
 */
export const participate = async (eventId: string, data: Record<string, unknown>) => {
  await bossClient.userEventClient.participateEvent(eventId, data);
};

export const getEventById = async ({
  locale,
  eventId,
}: {
  locale: string;
  eventId: string;
}): Promise<IEvent | null> => {
  const event = await bossClient.userEventClient.getEventById(locale, eventId);

  if (event) {
    return transform({ event });
  }

  return null;
};
