import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { storePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';
import { Alert, Link, Presence } from '@boss/ui';

import { COLORA_LINK } from '../../constants';
import { FormType, useFormField, useFormSubmit, useProfile } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS_LOGGED_IN = [
  'clientnumber',
  'salutation',
  'companyname',
  'firstname',
  'lastname',
  'email',
  'participants',
];
const FORM_FIELD_KEYS_NOT_LOGGED_IN = [
  'customerType',
  'firstname',
  'lastname',
  'companyname',
  'phonenumber',
  'vatnumber',
  'email',
  'participants',
];

type FormFieldKey = (typeof FORM_FIELD_KEYS_LOGGED_IN | typeof FORM_FIELD_KEYS_NOT_LOGGED_IN)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  type?: FormType;
  eventId: string;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const EducationForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type = 'education-form',
  eventId,
}: Props) => {
  const { data: profile, isLoggedIn } = useProfile();
  const fieldsToShow = [
    ...(initialFieldsToShow ?? (isLoggedIn ? FORM_FIELD_KEYS_LOGGED_IN : FORM_FIELD_KEYS_NOT_LOGGED_IN)),
  ];
  const { onSubmit: submitForm, isSubmitting, isSuccess, reset } = useFormSubmit();
  const { t } = useTranslation('forms');
  const { locale } = useRouter();

  const { clientnumber, companyname, email, firstname, lastname, phonenumber } = useFormField();

  const [customerType, setCustomerType] = useState();

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    if (customerType !== formik.values.customerType) {
      setCustomerType(formik.values.customerType);
    }
  };

  const colStyle = 'col-span-3';

  const baseFields: FormField[] = [
    ...(isLoggedIn
      ? [clientnumber]
      : [
          {
            colStyle,
            name: 'customerType',
            type: 'select' as const,
            label: t('fields.customerType') ?? '',
            errorLabel: t('errors.customerType') ?? '',
            options: [
              { value: 'professional', label: t('select.customerType.professional') ?? '' },
              { value: 'private', label: t('select.customerType.private') ?? '' },
            ],
            placeholder: t('placeholders.select'),
          },
          {
            name: 'vatnumber',
            key: 'vatnumber',
            label: t('fields.vatnumber') ?? '',
            errorLabel: t('errors.vatnumber') ?? '',
            initialValue: '',
            type: 'input' as const,
            validationType: 'VAT' as const,
          },
          phonenumber,
        ]),
    {
      ...companyname,
      colStyle,
    },
    {
      ...email,
      colStyle,
    },
    {
      ...firstname,
      colStyle: !isLoggedIn ? 'col-start-1 col-end-4' : colStyle,
    },
    {
      ...lastname,
      colStyle,
    },
    {
      label: t('fields.numberOfParticipants'),
      name: 'participants',
      type: 'counterList',
      colStyle: 'col-start-1 col-end-4',
      required: true,
      placeholder: t('placeholders.nameOfParticipant') ?? '',
      listTitle: t('placeholders.nameOfParticipants') ?? '',
    },
  ];

  const getFieldsToShow = (): FormFieldKey[] => {
    return fieldsToShow ?? (isLoggedIn ? FORM_FIELD_KEYS_LOGGED_IN : FORM_FIELD_KEYS_NOT_LOGGED_IN);
  };

  const handleSubmit = (vals: FormValues) => {
    const numberOfParticipants =
      typeof vals.participants === 'object' && 'count' in vals.participants ? vals.participants.count : 0;

    const participants =
      typeof vals.participants === 'object' && 'items' in vals.participants && Array.isArray(vals.participants.items)
        ? vals.participants.items.join(', ')
        : '';

    submitForm(isLoggedIn ? 'education-form' : 'education-form-visitor', {
      ...vals,
      contactpersonid: (profile?.extension_ContactPersonId as string) ?? '',
      numberOfParticipants: numberOfParticipants.toString(),
      message: participants,
      orderreference: eventId,
      orderid: eventId,
    });
  };

  const isPrivate = customerType === 'private';

  return (
    <Presence className={twMerge('flex flex-col gap-4', className)} id="help-form-presence" visible>
      {isPrivate && (
        <Alert className="grid gap-5 md:grid-cols-2" type="warning">
          <span>{t('note.private.1')}</span>{' '}
          <Link className="underline" href={COLORA_LINK}>
            {t('note.private.2')}
          </Link>{' '}
          <span>{t('note.private.3')}</span>{' '}
          <Link className="underline" href={storePageSlugs[locale]}>
            {t('note.private.4')}
          </Link>
        </Alert>
      )}

      <DynamicForm
        eventId={eventId}
        fields={buildFormFields(baseFields, getFieldsToShow(), additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onFormValuesChange={handleFormValuesChange}
        onSubmit={handleSubmit}
        showSubmitButton={!isPrivate}
        variant="light"
      />
    </Presence>
  );
};

export default EducationForm;
