import * as bossClient from '@boss/boss-client';
import { TrainingFields } from '@boss/types/b2b-b2c';

import { IEvent } from './types';

/**
 * Transform event from bossClient Event to IEvent
 * @param event
 * @returns {IB2BTraining}
 */

export const transform = ({
  event,
  contentfullTraining,
  relatedEvents,
}: {
  event: bossClient.types.event.Event;
  contentfullTraining?: TrainingFields;
  relatedEvents?: IEvent[];
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): IEvent => {
  return {
    id: event.id ?? '',
    status: event.status ?? '',
    type: event.type ?? '',
    title: contentfullTraining?.title ?? event.description ?? '',
    location: event.location ?? '',
    locationId: event.locationid ?? '',
    productLink: event.productlink ?? '',
    date: event.date ?? '',
    startTime: event.starttime ?? '',
    endTime: event.endtime ?? '',
    duration: event.duration ?? '',
    price: event.price ?? 0,
    complete: event.complete ?? false,
    numberOfParticipants: event.numberofparticipants ?? 0,
    language: event.language ?? '',
    image: contentfullTraining?.image ?? null,
    description: contentfullTraining?.description ?? null,
    prefixSections: contentfullTraining?.prefixSections ?? null,
    suffixSections: contentfullTraining?.suffixSections ?? null,
    relatedEvents: relatedEvents ?? [],
  };
};
