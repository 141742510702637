import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { trackEvents } from '@boss/constants/shared';
import { useIsSSR, useRouter } from '@boss/hooks';
import { RichText } from '@boss/rich-text';
import { IProduct } from '@boss/services';
import { ColorGroup, IArticle, SimpleColor } from '@boss/types/b2b-b2c';
import { Button, Characteristics, ProductDetailCard, ProductInspirationImages, Section } from '@boss/ui';

import Accordion from './accordion';
import { getCharacteristicsItems, getProductDetailTitle, getTechnicalDocumentInfo } from './productDetailMapper';
import { useAccount, useAccountPriceInfoByProductId, useProductsById } from '../../client-queries';
import {
  CarouselProductsWrapper,
  ProductAverageReview,
  ProductCardWrapper,
  ProductPanelWrapper,
  ProductReviewList,
} from '../../components';
import { B2B_CAROUSEL_SETTINGS, CAROUSEL_SETTINGS } from '../../constants';
import { useAddToBasket, useEventTracker, useFavorites, usePrice, useProductMap, useProfile } from '../../hooks';
import { getProductColorType, getTotalPriceArticles, isB2b, productCardVariant, productPageConfig } from '../../utils';
import { PagePropsContext } from '../googleTagManager';

type Props = {
  product: IProduct;
  colorGroups: ColorGroup[] | null;
  seoTitle?: string;
};

// Not sure how we can fix this as it's difficult to split up this component
// eslint-disable-next-line sonarjs/cognitive-complexity
const ProductDetailPage = ({ product, colorGroups, seoTitle }: Props) => {
  const { t } = useTranslation(['product', 'stores', 'common']);
  const { locale } = useRouter();
  const { isLoggedIn } = useProfile();
  const { data: account } = useAccount(locale);
  const { data: accountPriceInfo, isLoading: priceIsLoading } = useAccountPriceInfoByProductId(product.id);

  const { needsLoginForBasket } = productPageConfig;
  const isSSR = useIsSSR();
  const [articles, setArticles] = useState<IArticle[]>([]);

  const colorType = getProductColorType(product);

  const showProductActions = (needsLoginForBasket && isLoggedIn && !isSSR) || !needsLoginForBasket;

  const [selectedColor, setSelectedColor] = useState<SimpleColor | undefined>();

  // If the selected color stays persistent when navigating between pdp's, illegal color/product combinations can occor.
  useEffect(() => {
    setSelectedColor(undefined);
  }, [product]);

  const { showPrice } = usePrice();
  const { mapProductLabels, mapProductComponent } = useProductMap();
  const { data: relatedProducts } = useProductsById(product.relatedProducts || []);
  const { favorites, toggleFavorite } = useFavorites();

  const pageProps = useContext(PagePropsContext);
  const { trackCustomEvent } = useEventTracker();

  const carouselProductsRef = useRef<HTMLDivElement | null>(null);

  // Add to basket state and trigger
  const {
    hasArticlesSelected,
    setSelectedArticleIdsObject,
    infoMessage,
    setInfoMessage,
    hasError,
    setHasError,
    selectedArticleIdsObject,
    addToBasket,
    isBasketUpdateLoading,
  } = useAddToBasket({ articles, selectedColor, product });

  const totalPrice = getTotalPriceArticles(selectedArticleIdsObject, articles);
  const price = showPrice ? totalPrice : undefined;

  const carouselProducts = product?.stopped ? product.alternativeProducts : relatedProducts;

  const scrollToCarouselProducts = () => {
    if (carouselProductsRef.current) {
      const elementPosition = carouselProductsRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('search') && searchParams.has('query')) {
      const query = searchParams.get('query');
      const trackingProperties = {
        keyword: query,
        productResultCount: 1,
        contentResultCount: 0,
        colorResultCount: 0,
        stepbystepResultCount: 0,
        inspirationResultCount: 0,
        totalresultCount: 1,
        pageInfo: pageProps,
        ExecutedofAutomaticSearch: 'automated',
      };

      trackCustomEvent(trackEvents.KEYWORD_SEARCHED, trackingProperties);
    }
    // to avoid firing multiple events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const trackingProps = {
      productId: product.id,
      productName: product.name,
      productCategory: product.categories?.[0]?.facet,
      pageInfo: pageProps,
      productSEOTitle: seoTitle,
    };

    trackCustomEvent(trackEvents.VIEW_ITEM, trackingProps);
    // to avoid firing multiple events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labels =
    product.labels || accountPriceInfo?.labels
      ? mapProductLabels([...(product?.labels ?? []), ...(accountPriceInfo?.labels ?? [])])
      : undefined;

  const fromPrice =
    accountPriceInfo?.prices?.fromPriceAmount && accountPriceInfo?.prices?.fromPriceAmount > 0
      ? accountPriceInfo?.prices?.fromPriceAmount
      : undefined;

  return (
    <div className="min-h-[90vh]">
      <Section
        className="pt-0"
        content={
          <div className={`${isB2b ? '-mx-5 md:mx-0' : ''} grid grid-cols-5 gap-5 md:gap-10`}>
            <div className="col-span-5 flex flex-col gap-5 md:gap-10 lg:col-span-3">
              <ProductDetailCard
                complementaryImages={!isB2b ? product.images : []}
                description={product.description}
                handleAddToWishlist={() => toggleFavorite('PRODUCTS', product.id)}
                id={product.id}
                inWishlist={favorites['PRODUCTS']?.includes(product.id)}
                labels={labels}
                price={fromPrice}
                priceIsLoading={priceIsLoading}
                productImage={product.packshot}
                renderRating={() => <ProductAverageReview elementSuffix="detail" id={product.id} />}
                showFromPrice={!!product.articles && !!fromPrice}
                showProductActions={showProductActions}
                specifications={product.usps?.map((usp, i) => (
                  <RichText
                    className="pb-2 pt-1"
                    content={usp}
                    key={i}
                    mapperOptions={{ locale }}
                    renderOptions={{ noParagraph: true }}
                  />
                ))}
                subtitle={product.seoTitle ? product.name : ''}
                technicalLink={
                  product.technicalSheet
                    ? getTechnicalDocumentInfo(product.technicalSheet, t('documents.technicalSheet'))
                    : null
                }
                title={getProductDetailTitle(product)}
                translations={{
                  ...t('detailCard', { ns: 'product', returnObjects: true }),
                  unitPrice: accountPriceInfo?.prices?.fromPriceUnit
                    ? t(`detailCard.fromPriceUnits.${accountPriceInfo?.prices?.fromPriceUnit}`, {
                        ns: 'product',
                      })
                    : '',
                }}
                variant={productCardVariant}
              />

              {!isB2b && (
                <>
                  <Characteristics items={getCharacteristicsItems(product, t)} />
                  {product.images?.length && (
                    <ProductInspirationImages className="hidden md:grid" images={product.images} />
                  )}
                </>
              )}

              <Accordion className="hidden md:block" locale={locale} product={product} />
            </div>
            <div className="col-span-5 flex flex-col gap-10 lg:col-span-2" id="product-panel-presence">
              {!product.stopped ? (
                <ProductPanelWrapper
                  addToBasket={addToBasket}
                  colorGroups={colorGroups}
                  colorType={colorType}
                  hasArticles={!!product.articles}
                  hasArticlesSelected={hasArticlesSelected}
                  hasError={hasError}
                  infoMessage={infoMessage}
                  isBasketUpdateLoading={isBasketUpdateLoading}
                  price={price}
                  product={product}
                  selectedArticleIdsObject={selectedArticleIdsObject}
                  selectedColor={selectedColor}
                  setArticles={setArticles}
                  setHasError={setHasError}
                  setInfoMessage={setInfoMessage}
                  setSelectedArticleIdsObject={setSelectedArticleIdsObject}
                  setSelectedColor={setSelectedColor}
                  showBaseColors={!!account?.accountinfo?.mixingmachine}
                  showProductActions={showProductActions}
                />
              ) : (
                <div className="shadow-m p- flex flex-col gap-5 bg-white p-5">
                  <div className="text-red text-center text-xl font-medium">
                    {t('unavailable.title', { ns: 'product' })}
                  </div>
                  <div>{t('unavailable.info', { ns: 'product' })}</div>
                  {!!carouselProducts?.length && (
                    <Button
                      className="text-left"
                      label={t('unavailable.link', { ns: 'product' })}
                      onClick={scrollToCarouselProducts}
                      type="link"
                    />
                  )}
                </div>
              )}
              <Accordion className="mx-5 md:hidden" locale={locale} product={product} />
            </div>
          </div>
        }
      />

      {!!carouselProducts?.length && (
        <div ref={carouselProductsRef}>
          <Section
            className={twMerge(!isB2b ? '' : 'bg-blue-light -mx-5 pt-6 md:mx-0 md:bg-transparent')}
            content={
              <CarouselProductsWrapper
                className={twMerge(!isB2b ? '' : 'mx-5 gap-5 md:mx-0 md:gap-10')}
                options={!isB2b ? CAROUSEL_SETTINGS : B2B_CAROUSEL_SETTINGS}
                products={carouselProducts}
                title={t(product.stopped ? 'alternativeProducts.title' : 'recommendedProducts.title', {
                  ns: 'product',
                })}
              >
                {carouselProducts.map((product, index) => {
                  const _trackInfo = {
                    index,
                    customEvent: trackEvents.VIEW_LIST_ITEM,
                    item_list_name: 'carousel product detail page (recommended products)',
                    item_id: product.id,
                    item_name: product.name,
                  };

                  return (
                    <ProductCardWrapper {...mapProductComponent(product)} key={product.id} trackInfo={_trackInfo} />
                  );
                })}
              </CarouselProductsWrapper>
            }
          />
        </div>
      )}

      {!isB2b && <Section content={<ProductReviewList productId={product.id} />} />}
    </div>
  );
};

export default ProductDetailPage;
