import { cva } from 'class-variance-authority';
import isString from 'lodash.isstring';
import { useTranslation } from 'next-i18next';

import { useRouter } from '@boss/hooks';
import { IProduct } from '@boss/services';
import { IAccount, IAppointment } from '@boss/services/client';
import { SectionFields } from '@boss/types/b2b-b2c';
import { AppointmentCard, Image } from '@boss/ui';

import { useContacts } from '../../../client-queries';
import { NewProducts, RecentlyViewedColors, RecentlyViewedProducts } from '../../../components';
import { ComponentMapper } from '../../../components';
import { DASHBOARD_CAROUSEL_SETTINGS } from '../../../constants';
import { useProfile } from '../../../hooks';
import { isB2b, accountDashboardVariant as variant } from '../../../utils/options';

/**
 * Accountpage dashboard container
 * @property {IAccount} account - the account information of the profile
 * @property {IAppointment[]} appointments - appointments represent on the profile
 * @property {IProduct[]} mostRecent - Most recent added products
 */
type Props = {
  account: IAccount;
  appointments?: IAppointment[];
  mostRecent?: IProduct[];
  b2bDashboardSections?: SectionFields[] | null;
};

const AccountVariant = cva('', {
  variants: {
    variant: {
      primary: 'font-medium',
      secondary: 'font-bold',
    },
  },
});

const AccountNumber = ({ accountNumber, className }: { accountNumber: string; className?: string }) => {
  const { t } = useTranslation('account');

  return (
    <span className={className}>
      {`${t('info.accountNumber')}: `}
      <span className={AccountVariant({ variant })}>{accountNumber}</span>
    </span>
  );
};

const Dashboard = ({ account, appointments, mostRecent, b2bDashboardSections }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation('account');
  const accountNumber = account.accountnumber;
  const { data: contacts, isLoading: contactsLoading } = useContacts(locale);
  const { data: profile } = useProfile();

  const contactInfo = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  return (
    <div className="pb-12 md:pr-7">
      <AccountNumber accountNumber={accountNumber} className="mb-3 flex justify-end sm:hidden" />
      <h1 className="mb-10">
        {variant === 'primary' ? (
          <>
            <div className="flex justify-between">
              <span className="h1 block font-normal">
                {!contactsLoading ? `Hi ${contactInfo?.firstname ?? account.accountinfo.name ?? ''},` : ''}
              </span>
              <AccountNumber accountNumber={accountNumber} className="hidden sm:block" />
            </div>
            <span className="h1 block">{t('welcome')}</span>
          </>
        ) : (
          <>
            <div className="flex justify-between">
              <span className="h1 font-brandon block">{t('welcome')}</span>
              <AccountNumber accountNumber={accountNumber} className="hidden sm:block" />
            </div>
            <span className={`h1 font-brandon text-brown block ${contactsLoading ? 'opacity-0' : ''}`}>{`${
              contactInfo?.firstname ?? (account.accountinfo.firstname || account.accountinfo.name)
            }!`}</span>
            <Image
              alt="blue stripe"
              className="max-w-95 -ml-20 object-contain lg:ml-0"
              height={300}
              src="/stripes/blue-stripe.svg"
              useNext
              width={700}
            />
          </>
        )}
      </h1>
      {!!(appointments?.length && !isB2b && isString(locale)) && (
        <>
          <h3 className="mb-4">{t('dashboard.nextAppointment')}</h3>
          <div className="mb-14 grid grid-cols-2 gap-5 md:grid-cols-3">
            {appointments.map(appointment => (
              <AppointmentCard key={appointment.appointmentid} {...appointment} locale={locale} />
            ))}
          </div>
        </>
      )}
      {!!(!!b2bDashboardSections?.length && isB2b) &&
        b2bDashboardSections?.map(section => <ComponentMapper entry={section} key={section.id} locale={locale} />)}
      {!isB2b && <RecentlyViewedProducts options={DASHBOARD_CAROUSEL_SETTINGS} />}
      {!isB2b && <RecentlyViewedColors />}
      {isB2b && !!mostRecent?.length && <NewProducts mostRecentProducts={mostRecent} />}
    </div>
  );
};

export default Dashboard;
