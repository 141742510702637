import { ParsedUrlQuery } from 'querystring';

import isString from 'lodash.isstring';
import { useTranslation } from 'next-i18next';
import { ComponentProps, useEffect, useRef, useState } from 'react';

import { paintguidePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { IColorGroup, IEnrichedQuestion, IPaintsystemOption, IPaintsystemOptionMap } from '@boss/services';
import { OverviewFields } from '@boss/types/b2b-b2c';

import Step, { Answer, StepTypeUnion } from './step';
import { useEventTracker } from '../..//hooks';
import { useColorByColorId, usePaintSystems } from '../../client-queries';
import { PaintguideWrapper } from '../../components';
import { CUSTOM_CALC, CUSTOM_COLOR, Q_PREFIX, RESULTS } from '../../constants';
import {
  getPaintguideSearchIndexName,
  getPaintguideStepsFromTranslations,
  isB2b,
  stepperVariant as pageVariant,
  paintguideQuestionAttributes,
} from '../../utils';

export type Steps = ComponentProps<typeof PaintguideWrapper>['steps'];

type Props = {
  pageTitle: string;
  optionsMap: IPaintsystemOptionMap;
  enrichedQuestions: Record<string, IEnrichedQuestion> | undefined;
  paintguideOverviewPage: OverviewFields | null;
  colorGroups: IColorGroup[];
};

const QUESTIONS = paintguideQuestionAttributes;
const QUESTION_ATTRIBUTES = Object.values(QUESTIONS);
// inverse of QUESTIONS object, for quick mapping of SEO friendly URL parameters
const QUESTIONS_MAP = Object.entries(QUESTIONS).reduce((map: Record<string, string>, [key, val]) => {
  map[val] = key;
  return map;
}, {});
// Map all the questions to the right step type
const STEP_TYPE_MAPPER: Record<string, StepTypeUnion> = {
  ...QUESTION_ATTRIBUTES.reduce((map: Record<string, StepTypeUnion>, att) => {
    map[att] = 'OPTION_SELECTOR';
    return map;
  }, {}),
  [CUSTOM_COLOR]: 'COLOR_PICKER',
  [CUSTOM_CALC]: 'SURFACE_CALC',
  [RESULTS]: 'RESULT',
};

/**
 * Validate if keys are valid question filters and if values are strings
 * Casts the ParsedUrlQuery as a more generic Record<string, string>
 */
const validateParsedUrlQuery = (
  urlQuery: ParsedUrlQuery,
  optionsMap: IPaintsystemOptionMap,
): urlQuery is Record<string, string> => {
  const extendedValidKeys = ['color', RESULTS];
  const validKeys = !Object.keys(urlQuery).some(key => !QUESTIONS[key]);
  const validStringAndIdValues = Object.entries(urlQuery).every(
    ([key, value]) => isString(value) && (optionsMap[value] || extendedValidKeys.includes(key)),
  );

  return validKeys && validStringAndIdValues;
};

/**
 * Takes a Record<string, string> and returns a querystring for Algolia
 * @example { question1.id: "1231", question2.id: "132344" } -> 'question1.id:"1231" AND question2.id:"132344"'
 */
const createQueryString = (filters: Record<string, string> | ParsedUrlQuery) => {
  const queryArr = [];

  for (const [attribute, value] of Object.entries(filters)) {
    if (attribute.startsWith(Q_PREFIX)) {
      queryArr.push(`${attribute}:"${value}"`);
    }
  }

  return queryArr.join(' AND ');
};

/**
 * @returns an seo friendly URL query Object
 * Currently the last step is not added because this is the same as going to the resultspage
 */
const createUrlparams = (filters: Record<string, string>) => {
  return Object.entries(filters)
    .filter(([key]) => key.startsWith(Q_PREFIX))
    .reduce((urlObj: Record<string, string>, [key, val]) => {
      urlObj[QUESTIONS_MAP[key]] = val;
      return urlObj;
    }, {});
};

const PaintguidePage = ({ enrichedQuestions, pageTitle, optionsMap, colorGroups, paintguideOverviewPage }: Props) => {
  const { locale, query: routeUrlParams, isReady, push: routerPush, replace: routerReplace } = useRouter();
  const { t } = useTranslation('paintguide');

  const [isStepperNavEvent, setIsStepperNavEvent] = useState(false);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [steps, setSteps] = useState<Steps>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [options, setOptions] = useState<IPaintsystemOption[]>([]);
  const indexname = getPaintguideSearchIndexName(locale);
  const [filterQuery, setFilterQuery] = useState('');
  const { data: paintsystems } = usePaintSystems(indexname, filterQuery);
  const [islastStep, setIslastStep] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [preselectedColorCode, setPreselectedColorCode] = useState('');
  const { data: preselectedColor } = useColorByColorId(preselectedColorCode);

  const currentAttribute = QUESTION_ATTRIBUTES[currentStep];

  const { trackCustomEvent } = useEventTracker();

  /**
   * Pass the new answers to update states/url
   * - Update the url to reflect the chosen answers
   * - Update the filterQuery to get the new option values from Algolia
   * - Update Answers react state
   */
  const handleNewAnswers = (newAnswers: Record<string, string>, updateUrl = true) => {
    if (updateUrl) {
      const params = Object.keys(newAnswers).length ? createUrlparams(newAnswers) : {};

      routerReplace(
        {
          pathname: `/${paintguidePageSlugs[locale]}`,
          query: params,
        },
        '',
        { shallow: true },
      );
    }

    setFilterQuery(createQueryString(newAnswers));
    setAnswers(newAnswers);
  };

  const handleStepChange = (nextStepIndex: number, stepperNavEvent?: boolean, newAnswers?: Record<string, string>) => {
    // Keep track from where the step change is triggered from
    // This is to know if a step should be autofilled and skipped or not.
    setIsStepperNavEvent(!!stepperNavEvent);

    // When going back, forget the selected answers for steps after the selected step (only on b2c)
    if (nextStepIndex < currentStep && !isB2b) {
      const newAnswers = { ...answers };

      QUESTION_ATTRIBUTES.forEach((attribute, index) => {
        if (index >= nextStepIndex) {
          delete newAnswers[attribute];
        }
      });

      setSteps(prevSteps =>
        prevSteps.map((prevStep, i) => {
          if (i < nextStepIndex) {
            return prevStep;
          }

          return {
            ...prevStep,
            selected: '',
          };
        }),
      );

      handleNewAnswers(newAnswers);
    }

    if (nextStepIndex === steps.length - 1) {
      if (Object.keys(newAnswers ?? answers).length < 3) {
        // If we call this function inside the setAnswer function, the newAnswers will not be available as state,
        // by passing the newAnswers and giving them priority when given, we always use the correct answers state
        return setShowInfoBox(true);
      }

      setShowInfoBox(false);
      setIslastStep(true);
      return;
    }
    setIslastStep(false);
    setCurrentStep(nextStepIndex);
  };

  const setAnswer = ({ attribute, value }: Answer) => {
    const newAnswers = {
      ...answers,
      [attribute]: value,
    };

    setSteps(prevSteps => {
      const newSteps = [...prevSteps];

      newSteps[currentStep] = {
        ...newSteps[currentStep],
        selected: optionsMap[value]?.answerText ?? value,
      };

      return newSteps;
    });

    handleStepChange(currentStep + 1, undefined, newAnswers);
    handleNewAnswers(newAnswers);
  };

  /** Set and Translate steps on locale switch */
  useEffect(() => {
    setSteps(getPaintguideStepsFromTranslations(QUESTION_ATTRIBUTES, t, enrichedQuestions));
    setCurrentStep(0);
    setIslastStep(false);
  }, [locale, t, enrichedQuestions]);

  /**
   * Catch the url params, validate and set the react states
   * */
  useEffect(() => {
    if (!routeUrlParams || !isReady || !validateParsedUrlQuery(routeUrlParams, optionsMap)) {
      return;
    }
    const urlParameterEntries = Object.entries(routeUrlParams);
    const urlAnswers = urlParameterEntries.reduce((answers: Record<string, string>, [key, val]) => {
      const mappedKey = QUESTIONS[key]; // Remap the seo friendly keys to the algolia attributes

      answers[mappedKey] = val;
      return answers;
    }, {});

    setSteps(prevSteps => {
      const newSteps = [...prevSteps];

      for (const [question, value] of urlParameterEntries) {
        const extractedNumber = parseInt(question.replace(/[^\d]/g, '')); // param q1, q2, ... -> 1, 2, ...
        const index = extractedNumber - 1; //zero indexed
        const selected = optionsMap[value]?.answerText ?? value;

        if (question === 'color') {
          // Trigger to fetch the color data
          setPreselectedColorCode(value);
        }

        newSteps[index] = {
          ...newSteps[index],
          selected,
          active: true,
        };
      }

      return newSteps;
    });
    handleNewAnswers(urlAnswers, false);

    // When a user saves a b2b result, an extra param is added to directly go to the results
    if (routeUrlParams[RESULTS] && isB2b) {
      setCurrentStep(QUESTION_ATTRIBUTES.length - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  // When a colorid is given in the url,
  // we need to call the bossapi and show the preselected color data inside the stepper
  useEffect(() => {
    if (!preselectedColor) {
      return;
    }
    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      const colorIndex = newSteps.findIndex(x => x.id === CUSTOM_COLOR);

      newSteps[colorIndex] = {
        ...newSteps[colorIndex],
        selected: preselectedColor.name,
        color: preselectedColor.rgb,
        active: false,
      };

      return newSteps;
    });
  }, [preselectedColor]);

  /**
   * When querystring changes -> request Algolia -> new paintsystems fetched
   * From the new paintsystem facets, get the enriched options from the option map
   * Redirect to the results page on the final q on b2c
   * Show results on b2b inside the step comp
   * */
  useEffect(() => {
    if (islastStep) {
      // Show results on page
      if (isB2b) {
        // Only go the the last step if more then 3 answers are given
        if (Object.keys(answers).length > 2) {
          setCurrentStep(steps.length - 1);
        }
        return;
      }
      // Go to results page on B2C
      if (paintsystems?.hits?.[0]) {
        const result = paintsystems.hits[0].objectID;

        routerPush({
          pathname: `${pageTitle}/${result}`,
          query: { sqm: answers[CUSTOM_CALC] ?? 0, color: answers[CUSTOM_COLOR] ?? '' },
        });
      }
    }

    if (paintsystems?.facets && paintsystems.facets[currentAttribute]) {
      const optionIds = Object.keys(paintsystems.facets[currentAttribute]);
      const _options = optionIds.map(id => optionsMap[id]);

      setOptions(_options);
    }
  }, [answers, steps.length, currentAttribute, paintsystems, optionsMap, routerPush, pageTitle, islastStep]);

  /**
   * Only on b2c functionality:
   * When there is only one option available on the next step,
   * autofill the answers and continue with the next multiple choise option
   *
   * Does not trigger when navigating through the stepper
   */
  useEffect(() => {
    if (!isB2b && !isStepperNavEvent && options.length === 1) {
      const answer: Answer = {
        attribute: currentAttribute,
        value: options[0].id,
      };

      const trackingProps = {
        wizardName: t('paintguideLabel'),
        step: currentStep + 1,
        stepLabel: steps[currentStep].label,
        stepValue: 0,
        stepValueLabel: options[0].answerText,
      };

      trackCustomEvent('wizard_interacted', trackingProps);
      setAnswer(answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]); // adding other dependencies results in infinite rerenders

  const resetAnswers = () => {
    setCurrentStep(0);
    setIslastStep(false);
    setAnswers({});
    setFilterQuery('');
    setShowInfoBox(false);
    setSteps(prevSteps => prevSteps.map(step => ({ ...step, selected: '' })));
    routerReplace(`/${paintguidePageSlugs[locale]}`, '', { shallow: true });
    goToTop();
  };

  const goToTop = () => {
    if (window && stepperRef.current) {
      stepperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleOnAnswer = (answer: Answer) => {
    goToTop();
    setAnswer(answer);
  };

  return (
    <PaintguideWrapper
      currentStep={currentStep}
      disableLastStep={Object.keys(answers).length < 3}
      enrichedQuestion={enrichedQuestions?.[currentAttribute]}
      handleStepChange={handleStepChange}
      id="paintguidePage"
      onCloseInfoBox={() => setShowInfoBox(false)}
      paintguideOverviewPage={paintguideOverviewPage}
      ref={stepperRef}
      showInfoBox={showInfoBox}
      steps={steps}
      variant={pageVariant}
      visible={!!steps.length && !!options.length}
    >
      <Step
        attribute={currentAttribute}
        colorGroups={colorGroups}
        currentStep={currentStep}
        handleStepChange={handleStepChange}
        islastStep={islastStep}
        onSetAnswer={handleOnAnswer}
        options={options}
        paintsystems={paintsystems?.hits}
        resetAnswers={resetAnswers}
        selectedOption={answers[currentAttribute]}
        steps={steps}
        type={STEP_TYPE_MAPPER[currentAttribute]}
      />
    </PaintguideWrapper>
  );
};

export default PaintguidePage;
