import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';
import 'yup-phone';

import { useAddressValidation } from '../../client-queries';
import { FormValues } from '../../components/DynamicForm';
import { FormField } from '../../components/Mappers/FormFieldMapper';
import { CountryValidationValues } from '../use-form-field/use-form-field';

/**
 * Gets the initial values for the form fields.
 *
 * @param {Array} fields - Array of form fields
 */
const getInitialValues = (fields: FormField[]) => {
  const initialValues: FormValues = {};

  fields.forEach(field => {
    initialValues[field.name] = field.initialValue ?? '';

    // If there are subfields, loop them and set the initial value for them.
    if ('fields' in field) {
      field.fields.forEach(f => {
        initialValues[f.name] = f.initialValue ?? '';
      });
    }
  });

  return initialValues;
};

/**
 * useForm hook which exports generic form functions
 */
export const useForm = () => {
  const { t } = useTranslation('forms');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: validateAddress } = useAddressValidation();

  const [lastValidatedAddress, setLastValidatedAddress] = useState({
    zipcode: '',
    city: '',
    country: '',
  });

  const [addressErrors, setAddressErrors] = useState<Yup.ValidationError[]>([]);

  const verifyRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      return true;
    }

    const token = await executeRecaptcha();

    return !!token;
  }, [executeRecaptcha]);

  /**
   * Creates and returns input field validation based on the validationType field value
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getInputFieldValidation = (field: Extract<FormField, { type: 'input' }>) => {
    const validationType = field.validationType;
    const fieldKey = field.key;

    if (fieldKey === 'zipcode') {
      return Yup.string().when(['city', 'country'], {
        is: (city: string, country: string) => !!city && !!country,
        then: Yup.string().test(
          'validate-address',
          t('errors.invalidZipcode') ?? '',
          async (value: string | undefined, context) => {
            if (typeof value === 'undefined') {
              return true;
            }
            const parent = context.parent;
            const { city, country, street, streetnumber, postbox } = parent;

            const mappedCountry = CountryValidationValues[country as keyof typeof CountryValidationValues];

            const currentAddress = {
              zipcode: value ?? '',
              city: city ?? '',
              country: mappedCountry ?? '',
              street: street ?? '',
              streetnumber: streetnumber ?? '',
              postbox: postbox ?? '',
            };

            // Check if any of the address fields have changed
            const hasAddressChanged = Object.keys(currentAddress).some(
              key =>
                currentAddress[key as keyof typeof currentAddress] !==
                lastValidatedAddress[key as keyof typeof lastValidatedAddress],
            );

            const errors = [];

            if (!hasAddressChanged) {
              if (addressErrors.length > 0) {
                return new Yup.ValidationError(addressErrors);
              }
              return true;
            }

            const validationData = await validateAddress(currentAddress);

            // Update the last validated address
            setLastValidatedAddress(currentAddress);

            if (!validationData?.zipcodevalidated) {
              errors.push(
                context.createError({
                  path: 'zipcode',
                  message: t('errors.invalidZipcode') ?? 'Invalid zipcode',
                }),
              );
            }

            if (!validationData?.cityvalidated) {
              errors.push(
                context.createError({
                  path: 'city',
                  message: t('errors.invalidCity') ?? 'Invalid city',
                }),
              );
            }

            if (!validationData?.countryvalidated) {
              errors.push(
                context.createError({
                  path: 'country',
                  message: t('errors.invalidCountry') ?? 'Invalid country',
                }),
              );
            }

            if (!validationData?.streetvalidated) {
              errors.push(
                context.createError({
                  path: 'street',
                  message: t('errors.invalidStreet') ?? 'Invalid street',
                }),
              );
            }

            if (!validationData?.streetnumbervalidated) {
              errors.push(
                context.createError({
                  path: 'streetnumber',
                  message: t('errors.invalidStreetnumber') ?? 'Invalid streetnumber',
                }),
              );
            }

            if (!validationData?.postboxvalidated) {
              errors.push(
                context.createError({
                  path: 'postbox',
                  message: t('errors.invalidPostbox') ?? 'Invalid postbox',
                }),
              );
            }

            if (errors.length > 0) {
              setAddressErrors(errors);
              return new Yup.ValidationError(errors);
            } else {
              setAddressErrors([]);
            }

            return true;
          },
        ),
      });
    }

    if (validationType === 'email') {
      return Yup.string().email(t('errors.email') ?? '');
    }

    if (validationType === 'number') {
      return Yup.number();
    }

    if (validationType === 'password') {
      return Yup.string().required(t('errors.password') ?? '');
    }

    return Yup.string();
  };

  const getBaseErrorLabel = (field: FormField) => {
    const baseLabel = t(`fields.${field.name}`, { ns: 'forms' });

    return field.errorLabel ?? t('errors.required', { ns: 'forms', value: baseLabel ?? field.name }) ?? '';
  };

  const getCounterListValidation = () => {
    return Yup.mixed().test(
      'counterList',
      t('errors.counterList') ?? '',
      (value: { count: number; items: string[] }) => {
        return value?.count === value?.items.length && value?.items.every(item => !!item) && value?.count > 0;
      },
    );
  };

  /**
   * Generic function which maps the given field based on its field type and applies the correct validation
   */
  const getFieldValidation = (field: FormField, allFields: FormField[]) => {
    const { name: fieldName, validation: initialValidation, required = true } = field;
    const baseLabel = t(`fields.${fieldName}`, { ns: 'forms' });
    const baseErrorLabel =
      field.errorLabel ?? t('errors.required', { ns: 'forms', value: baseLabel ?? fieldName }) ?? '';

    const validation = initialValidation ?? getTypeSpecificValidation(field, allFields);

    return required ? validation.required(baseErrorLabel) : validation;
  };

  /**
   * Generic function which maps the given field based on its field type and applies the correct validation
   */
  const getTypeSpecificValidation = (field: FormField, allFields: FormField[]) => {
    switch (field.type) {
      case 'input':
        return getInputFieldValidation(field);
      case 'phone':
        return Yup.string().test('phone', t('errors.phonenumber') ?? '', value => {
          if (!value) {
            return false;
          }
          const normalizedValue = value.replace(/^00/, '+');

          return isValidPhoneNumber(normalizedValue);
        });
      case 'checkboxGroup':
        return Yup.array();
      case 'checkbox':
        return field.required ? Yup.bool().isTrue(getBaseErrorLabel(field)) : Yup.bool();
      case 'counter':
        return Yup.number().min(0);
      case 'counterList':
        return field.required ? getCounterListValidation() : Yup.mixed();
      default:
        return Yup.string();
    }
  };

  /**
   * Generates the validation schema for the given form fields
   */
  const generateValidationSchema = (fields: FormField[]) => {
    const allFields: FormField[] = [];

    fields.forEach(field => {
      allFields.push(field);

      if ('fields' in field) {
        allFields.push(...field.fields);
      }
    });

    return allFields.reduce(
      (prev, curr) =>
        prev.shape({
          [curr.name]: getFieldValidation(curr, allFields),
        }),
      Yup.object(),
    );
  };

  return { generateValidationSchema, getInitialValues, verifyRecaptcha };
};
