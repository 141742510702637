import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useCollapse } from 'react-collapsed';
import { twMerge } from 'tailwind-merge';

import { IAccordionProps } from '../';

const AccordionPrimary = ({
  title,
  children,
  ariaLabel = 'accordion-',
  testId = 'accordion-section',
  titleClassName,
  iconClassName,
  hideDivider,
  noIndent,
  wrapperClassName,
}: IAccordionProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const ContentStyle = cva('', {
    variants: {
      noIndent: {
        true: 'pr-3',
        false: 'px-3',
      },
    },
  });

  return (
    <div className={wrapperClassName} data-testid={testId}>
      <div
        aria-label={ariaLabel + 'toggle'}
        className="flex items-start justify-between py-3 md:pb-2"
        {...getToggleProps()}
      >
        <span className={twMerge('md:m-w-2/3 flex items-center text-xl', titleClassName)}>{title}</span>
        <FontAwesomeIcon
          className={twMerge('md:text-blue ml-3 mt-1 md:text-xl', iconClassName)}
          icon={isExpanded ? faChevronUp : faChevronDown}
        />
      </div>
      <div aria-label={ariaLabel + 'collapse'} {...getCollapseProps()}>
        <div
          className={ContentStyle({
            noIndent,
          })}
        >
          {children}
        </div>
      </div>
      {!hideDivider && <span className="h-0.25 border-t-1 mb-12 block w-full pt-1"></span>}
    </div>
  );
};

export default AccordionPrimary;
