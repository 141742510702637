/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'next-i18next';
import { ChangeEvent, useEffect, useState } from 'react';

import { paintguidePageSlugs, storePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { IProduct } from '@boss/services';
import { IArticle, ISearchProduct } from '@boss/types/b2b-b2c';
import { Button, InputUnit, Link, Modal, Select, SquaredUnit, SurfaceCalculator, Tooltip } from '@boss/ui';
import { calcAmountOfpaintForSurface, calcTotalPaintVolume } from '@boss/utils';

import { CONSUMPTION_UNIT } from '../../constants';
import { getArticlesForSurface, isB2b, surfaceCalculationConfig } from '../../utils';

type ConsumptionOption = {
  technique: string;
  usage: {
    average: number;
    min: number;
    max: number;
  };
};

type Calculated = {
  paintSurfaces: Surface[];
  noPaintSurfaces: Surface[];
  activeConsumption: ConsumptionKey;
  activeTechnique: string;
  totalPaintNeeded: number;
  layers: number;
};

type Props = {
  onClose: () => void;
  onConfirm: (calculated: Calculated) => void;
  consumptionOptions: ConsumptionOption[];
  calculated?: Calculated;
  preCalculated?: number;
  product?: IProduct | ISearchProduct | null;
  articles?: IArticle[];
};

type Surface = {
  length: number;
  width: number;
  total: number;
};

const CONSUMPTION_KEYS = ['max', 'average', 'min'] as const;

type ConsumptionKey = (typeof CONSUMPTION_KEYS)[number];

const getActiveConsumptionOption = (options: ConsumptionOption[], technique: string) =>
  options.find(option => option.technique === technique);

const SurfaceCalculatorModal = ({
  preCalculated = 0,
  calculated,
  onClose,
  consumptionOptions,
  onConfirm,
  product,
  articles,
}: Props) => {
  const { t } = useTranslation(['product', 'common']);

  let usedTechnique = consumptionOptions?.[0]?.technique;

  if (!surfaceCalculationConfig.showTechnique && product) {
    if (product.roller !== 0) {
      usedTechnique = getActiveConsumptionOption(consumptionOptions, 'roller')?.technique ?? 'roller';
    } else if (product.roller === 0 && product.brush !== 0) {
      usedTechnique = getActiveConsumptionOption(consumptionOptions, 'brush')?.technique ?? 'brush';
    }
  }

  const [activeTechnique, setActiveTechnique] = useState(usedTechnique);
  const [activeConsumption, setActiveConsumption] = useState<ConsumptionKey>('average');
  const [paintSurfaces, setPaintSurfaces] = useState<Surface[]>([]);
  const [noPaintSurfaces, setNoPaintSurfaces] = useState<Surface[]>([]);
  const [preFilledSurfaces, setPreFilledSurfaces] = useState<Surface[]>([]);
  const [layers, setLayers] = useState(1);
  const { locale } = useRouter();

  const totalPaint = paintSurfaces.reduce((acc, curr) => acc + curr.total, 0);
  const totalNoPaint = noPaintSurfaces.reduce((acc, curr) => acc + curr.total, 0);
  const totalSurface = Number(Math.max(0, totalPaint - totalNoPaint).toFixed(2));
  const activeConsumptionOption = getActiveConsumptionOption(consumptionOptions, activeTechnique);

  const activeUsage = activeConsumptionOption?.usage[activeConsumption] ?? 1;
  const amountOfPaintNeeded = calcAmountOfpaintForSurface(activeUsage, totalSurface);
  const exactTotalPaintNeeded = calcTotalPaintVolume(amountOfPaintNeeded, layers);
  const articlesNeeded = getArticlesForSurface(exactTotalPaintNeeded, articles);

  const totalPaintNeeded = articlesNeeded
    ? Object.entries(articlesNeeded).reduce((acc, [id, value]) => {
        const article = articles?.find(a => a.id === id);

        return acc + value.quantity * (article?.quantity ?? 0);
      }, 0)
    : 0;

  const genericTranslations = {
    add: t('add', { ns: 'common' }),
    height: t('height', { ns: 'common' }),
    length: t('length', { ns: 'common' }),
    width: t('width', { ns: 'common' }),
  };

  const onLayersChange = (e: ChangeEvent<HTMLInputElement>): void => setLayers(Math.min(Number(e.target.value), 5));

  useEffect(() => {
    if (calculated) {
      setActiveConsumption(calculated.activeConsumption);
      setActiveTechnique(calculated.activeTechnique);
      setLayers(calculated.layers);
    }
  }, []);

  useEffect(() => {
    if (preCalculated) {
      setPreFilledSurfaces([
        {
          width: 0,
          length: 0,
          total: preCalculated,
        },
      ]);
    }
  }, [preCalculated]);

  const onConsumptionChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void =>
    setActiveConsumption(e.target.value as ConsumptionKey);

  return (
    <Modal
      ariaLabel="surface-calculator-modal"
      className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-x-10 md:gap-y-10"
      onClose={onClose}
    >
      <h3 className="md:col-span-full">{t('productPanel.surfaceCalculator.title')}</h3>

      {surfaceCalculationConfig.showTechnique && (
        <div className="flex flex-col gap-4">
          <span className="font-bold">{t('productPanel.surfaceCalculator.techniqueTitle')}</span>
          <Select
            name="technique"
            onChange={e => setActiveTechnique(e.target.value)}
            options={consumptionOptions.map(option => ({
              value: option.technique,
              label: t(`productPanel.surfaceCalculator.consumptionOptions.${option.technique}`),
            }))}
            value={activeTechnique}
          />
        </div>
      )}
      <div className={`${!surfaceCalculationConfig.showTechnique ? 'col-span-2' : ''} flex flex-col gap-4`}>
        <span className="font-bold">{t('productPanel.surfaceCalculator.consumptionTitle')}</span>
        <Select
          name="consumption"
          onChange={onConsumptionChange}
          options={CONSUMPTION_KEYS.map(option => ({
            value: option,
            label: t(`productPanel.surfaceCalculator.consumption.${option}`),
          }))}
          value={activeConsumption}
        />
      </div>

      <SurfaceCalculator
        onCalculate={setPaintSurfaces}
        preFilledSurfaces={calculated?.paintSurfaces || preFilledSurfaces}
        translations={{
          ...genericTranslations,
          title: t('productPanel.surfaceCalculator.includeSurfaceTitle'),
        }}
      />

      <SurfaceCalculator
        onCalculate={setNoPaintSurfaces}
        preFilledSurfaces={calculated?.noPaintSurfaces}
        translations={{
          ...genericTranslations,
          title: t('productPanel.surfaceCalculator.excludeSurfaceTitle'),
        }}
      />

      <div className="relative flex h-fit flex-col gap-4">
        {!isB2b && (
          <Tooltip
            buttonClassName="relative bottom-0 left-0"
            className="inline-flex flex-row gap-2"
            content={
              <span>
                <span className="caption">{t('productPanel.surfaceCalculator.layersTooltip1', { ns: 'product' })}</span>
                <Link className="caption text-blue-dark mx-1 underline" href={storePageSlugs[locale]}>
                  {t('productPanel.surfaceCalculator.layersTooltipCta1', { ns: 'product' })}
                </Link>
                <span className="caption">{t('productPanel.surfaceCalculator.layersTooltip2', { ns: 'product' })}</span>
                <Link className="caption text-blue-dark mx-1 underline" href={paintguidePageSlugs[locale]}>
                  {t('productPanel.surfaceCalculator.layersTooltipCta2', { ns: 'product' })}
                </Link>
              </span>
            }
            floatingClassName="max-w-105"
          >
            <span className="font-bold">{t('productPanel.surfaceCalculator.layersTitle')}</span>
          </Tooltip>
        )}
        <InputUnit max="5" min="1" name="paint-calculator-input" onChange={onLayersChange} step="1" value={layers} />
        <span className="caption absolute -bottom-5 left-0">
          {t('productPanel.surfaceCalculator.layersDisclaimer')}
        </span>
      </div>

      <div className="flex flex-col flex-wrap items-end justify-end gap-6 md:col-span-full">
        <span className="h4">
          {t('productPanel.surfaceCalculator.totalSurface')}: {totalSurface} <SquaredUnit className="h4" />
        </span>
        <Tooltip content={<span className="min-w-105 caption">{t('productPanel.surfaceCalculator.disclaimer')}</span>}>
          <span className="h4">
            {t(
              `productPanel.surfaceCalculator.${
                product?.consumptionUnit === CONSUMPTION_UNIT.KILO ? 'totalPaintInKg' : 'totalPaintInLiters'
              }`,
              { total: totalPaintNeeded },
            )}
          </span>
        </Tooltip>
      </div>

      <Button
        className="justify-self-end md:col-span-full"
        label={t('confirm', { ns: 'common' })}
        onClick={() => {
          onConfirm({
            paintSurfaces,
            noPaintSurfaces,
            layers,
            activeTechnique,
            activeConsumption,
            totalPaintNeeded,
          });
          onClose();
        }}
        type="primary"
      />
    </Modal>
  );
};

export default SurfaceCalculatorModal;
