import * as bossClient from '@boss/boss-client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import { IBasket, IBasketDeliveryAddress, IBasketLine, IBasketPatchLine } from './types';

export const getBasket = async (accountId?: string): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.getBasket(accountId);
export const getBasketById = async (basketId: string): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.getBasketById(basketId);

export const addBasketLines = async (
  basketId: string,
  basketLines: DeepPartial<IBasketLine>[],
): Promise<IBasket | undefined | null> => await bossClient.basketClient.addBasketLines(basketId, basketLines);

export const deleteBasketLine = async (basketId: string, basketLineId: string): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.deleteBasketLine(basketId, basketLineId);

export const updateBasketLine = async (
  basketId: string,
  basketLine: DeepPartial<IBasketLine>,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.updateBasketLine(basketId, basketLine);

export const updateBasket = async (
  basketId: string,
  basket: DeepPartial<IBasket>,
  locale: string,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.updateBasket(basketId, basket, locale);

export const patchBasket = async (
  basketId: string,
  patchLines: IBasketPatchLine[],
): Promise<IBasket | undefined | null> => await bossClient.basketClient.patchBasket(basketId, patchLines);

export const getDeliveryDates = async ({
  storeId,
  modeOfDelivery,
  address,
}: {
  storeId?: string;
  modeOfDelivery: number;
  address?: IBasketDeliveryAddress;
}): Promise<{ dates: string[]; deliverymode: string } | undefined | null> => {
  const data = await bossClient.basketClient.getDeliveryDates({ storeId, modeOfDelivery, address });

  return data ?? undefined;
};

export const addVoucherToBasket = async (
  basketId: string,
  voucher: { type: string; reference: string; webcode?: string },
) => await bossClient.basketClient.addVoucherToBasket(basketId, voucher);

export const deleteVoucherFromBasket = async (basketId: string, voucherId: string) =>
  await bossClient.basketClient.deleteVoucherFromBasket(basketId, voucherId);
