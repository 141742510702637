import * as bossClient from '@boss/boss-client';
import { IArticle } from '@boss/types/b2b-b2c';

import { parseNumber, parsePrices, parseQuantity } from './parsers';

export const mapArticle = (article: bossClient.types.article.Article, channel: string): IArticle => ({
  description: article.publicationdescription ?? '',
  id: article.id,
  name: article.itemname ?? null,
  pricing: parsePrices(article.prices, channel),
  productId: article.productgroupid,
  searchTerm: article.searchname,
  unit: article.priceunit,
  color: {
    code: article.color?.code ?? '0',
    description: article.color?.description ?? '',
  },
  stockproductnumber: article.stockproductnumber,
  displayorder: parseNumber(article.displayorder),
  quantity: parseQuantity(article.packaging.packagingquantity) ?? null,
  packaging: {
    piecesPerPackage: parseNumber(article.piecesperpackage) ?? null,
    piecesPerPallet: parseNumber(article.piecesperpallet) ?? null,
    units: article.packagingunits ?? [],
  },
  mymx: article.colortypegroups?.includes('mymx'),
  colortypegroups: article.colortypegroups,
  barcodes: article.barcodes?.map(barcode => barcode.barcode) ?? [],
  stockproductbase: article.stockproductbase ?? '',
});
