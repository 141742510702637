import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';

import { IEvent } from '@boss/services';
import { Button, EventCard } from '@boss/ui';
import { formatDate, getLocalizedSlugForEventPage, slugify } from '@boss/utils';

import { eventConfig } from '../../utils';

const EventCardWrapper = ({ event, locale }: { event: IEvent; locale: string }) => {
  const eventLink = getLocalizedSlugForEventPage(locale, slugify(event.id));
  const { variant } = eventConfig;
  const { t } = useTranslation('event');

  return (
    <EventCard
      footerAction={
        !event.complete ? (
          <Button
            className="py-3"
            href={eventLink}
            icon={faArrowRight}
            iconPosition="right"
            label={t('sections.overview.actions.register')}
            type="primary"
          />
        ) : (
          <Button
            className="bg-red-dark py-3"
            href={eventLink}
            label={t('sections.overview.actions.full')}
            type="primary"
          />
        )
      }
      image={event.image}
      key={event.id}
      title={event.title}
      variant={variant}
    >
      <div className="text-gray py-3">
        {event.date && <p>{formatDate(event.date)}</p>}
        {event.location && <p>{event.location}</p>}
      </div>
    </EventCard>
  );
};

export default EventCardWrapper;
